@import "minima";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


// Home Page
.mega {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 80px;
	justify-content: space-between;
}

.home-container {
	display: flex;
	flex-wrap: wrap;
	width: 45%;
	flex-direction: column;
	align-items: flex-start;
}

.home-icon {
	display: none;
}

.home-screenshot {
	object-fit: contain;
	width: 50%;
}

.home-title {
	margin-top: 100px;
}

.store-links {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.appstore, .playstore {
	padding-right: 20px;
}

@media only screen and (max-width: 400px) {
	.home-icon {
		display: flex;
		align-self: center;
		height: 150px;
		width: 150px;
	}
	
	.home-title {
		margin-top: 20px;
	}
	
	.home-screenshot {
		display: none;
	}
	
	.home-container {
		width: 100%;
	}
}

.splash-text {
	margin-bottom: 60px;
}



// App Detail Page
.app-detail-header {
	display: flex;
	flex-wrap: wrap;
	/* <!-- justify-content: flex-start;   default value --> */
	align-items: center;
}

.app-detail-description, .app-detail-content {
	width: 100%;
	display:flex;
}

.app-detail-content {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}


@media only screen and (min-width: 401px) {
	.app-detail-image, .app-detail-title {
		margin-bottom: 50px;
	}

	.app-detail-image {
		margin-right: 50px;
	}
}

.iconTop {
	color: orange;
}

.iconBack {
	color: #e3e3e3;
}

.features {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}

.feature {
	width: 100%;
	margin-bottom: 20px;
	display: flex;
}

.svg-icon {
	padding-right: 0px;
}

@media only screen and (min-width: 401px) {
	.feature {
		width: 28%;
		justify-content: center;
	}
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: 10px;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: 0;
  padding-left: $spacing-unit / 2;
}
